import React, { Component } from "react";

export class testimonials extends Component {
  render() {
    return (
      <div id="testimonials">
        <div className="container">
          <div className="section-title text-center">
            <h2>Referanslarımız</h2>
          </div>
          <div className="rowOfRef">
            <div className="rowOfLeft" style={{ fontFamily: 'Georgia', color: "black" }} >
              <p style={{ fontFamily: 'Georgia', color: "black" }}>
                • <b>1992</b>: Ankara Çankaya’da Mimari-Tasarım, Tadilat-Dekorasyon, Showroom Mağaza ve Uygulama Merkezi
              </p>
              <p >
                • <b>1993</b>: Ankara Ayaş Tedavi ve Termal Tesisleri Yapım İşi
              </p>
              <p>   
                • <b>1994</b>: Ankara Sincan Yenikent İlköğretim Okulu İnşaatı
              </p>
              <p>
                •	<b>1995</b>: Ankara Şereflikoçhisar Yatılı Bölge İlköğretim Okulu Onarım İşi
              </p>
              <p>
                •	<b>1995</b>: Ankara Çubuk Sirkeli Özel Eğitim ve Uygulama Okulu Onarım İşi
              </p>
              <p>
                •	<b>1996</b>: Ankara Çubuk Sirkeli İlköğretim Okulu Kat İlavesi Yapım İşi
              </p>
              <p>
                •	<b>1998</b>: Ankara Keçiören Dumlupınar 18 Derslikli İlköğretim Okulu İnşaatı
              </p>
              <p>
                •	<b>1998</b>: Ankara Keçiören 18 Derslikli Nuh Eskiyapan İlköğretim Okulu İnşaatı
              </p>
              <p>
                •	<b>1999</b>: Ankara Akyurt Güzelhisar 8 Derslikli İlköğretim Okulu İnşaatı
              </p>
              <p>
                •	<b>1999</b>: Ankara Etimesgut (MLO) İlköğretim Okulu Spor Salonu ve Teknoloji Merkezi İnşaatı
              </p>
              <p>
                •	<b>2000</b>: Ankara Akyurt Merkez 24 Derslikli İlköğretim Okulu İnşaatı ve Çevre Düzenlemesi İşi
              </p>
              <p>
                •	<b>2000</b>: Ankara Akyurt Kozayağı 8 Derslikli İlköğretim Okulu İnşaatı
              </p>
              <p>
                •	<b>2001</b>: Ankara Etimesgut Dumlupınar 24 Derslikli İlköğretim Okulu İnşaatı
              </p>
              <p>
                •	<b>2001</b>: Ankara Akyurt Kozayağı İlköğretim Okulu Çevre Tanzimi, İhata Duvarı, Saha Betonu, Öğretmen Lojmanı Onarım İşi
              </p>
              <p>
                •	<b>2004</b>: Ankara Çankaya Yıldız Prestıge Trade Center İş Merkezi Binası İnşaatı
              </p>
              <p>
                •	<b>2006</b>: Ankara Mamak Cengizhan İlköğretim Okulu İnşaatı
              </p>
              <p>
                •	<b>2006</b>: Ankara Sincan Melikşah 14 Derslikli Ek Bina İnşaatı
              </p>
              <p>
                •	<b>2006</b>: Ankara Çayyolu Fevzi Özbey İlköğretim Okulu Kaba İnşaatı
              </p>
              <p>
                •	<b>2007</b>: Kastamonu Acil Yardım ve Trafik Hastanesi İnşaatı
              </p>
              <p>
                •	<b>2008</b>: Ankara Keçiören Uygur İlköğretim Okulu İnşaatı
              </p>
            </div>
            <div className="rowOfRight" style={{ fontFamily: 'Georgia', color: "black" }}>
              
              <p>
                •	<b>2010</b>: Ankara Yenimahalle Zehra Önder İlköğretim Okulu İnşaatı
              </p>
              <p>
                •	<b>2010</b>: Ankara Beypazarı İlköğretim Okulu İnşaatı
              </p>
              <p>
                •	<b>2010</b>: Ankara Ayaş İlçesi Cumhuriyet Meydanı Çeşmesi Restorasyonu İşi
              </p>
              <p>
                •	<b>2011</b>: İzmir Vakıfbank Gazi Bulvarı Şubesi Tadilat ve Dekorasyon İnşaatı
              </p>
              <p>
                •	<b>2012</b>: Ankara Kızılay PTT BYS Müdürlüğü ve Misafirhane (OTEL) Binası İnşaatı
              </p>
              <p>
                • <b>2013</b>: Ankara İl Özel İdaresi Pursaklar Saray İlköğretim Okulu İnşaatı
              </p>
              <p>
                • <b>2013</b>: Samsun Atakum PTT Merkez Müdürlüğü Ve Koli Kargo İle Uygulamalı Eğitim Otel Binası İnşaatı
              </p>
              <p>
                • <b>2013</b>: Muğla Bodrum Yaprak Villa Evleri 7 adet Müstakil Villa İnşaatı
              </p>
              <p>
                • <b>2014</b>: Ankara Kalecik Ramise-Orhan Kalkan Ortaöğretim Öğrenci Yurdu İnşaatı
              </p>
              <p>
                • <b>2015</b>: Ankara Dodurga Mahallesi Yenimahalle Anadolu Lisesi İnşaatı
              </p>
              <p>
                • <b>2015</b>: Ankara Etimesgut Şeyh Şamil Ortaokulu İnşaatı
              </p>
              <p>
                • <b>2016</b>: Muğla Bodrum Niltaş Yalıkavak Konut ve İş Merkezi İnşaatı
              </p>
              <p>
                • <b>2017</b>: Ankara Mamak Kıbrıs Mahallesi Ortaokulu,Anaokulu ve Spor Salonu İnşaatı
              </p>
              <p>
                • <b>2018</b>: Ankara Aselsan İlkokulu İnşaatı
              </p>
              <p>
                • <b>2018</b>: Muğla Fethiye Üzümlü 8 Derslikli Ortaokul İnşaatı
              </p>
              <p>
                • <b>2019</b>: Ankara Sincan Fatih Mesleki ve Teknik Lise,Spor Salonu ve Atölyeler İnşaatı
              </p>
              <p>
                • <b>2019</b>: Ankara Gölbaşı Seğmenler Mahallesi Kız Meslek Lisesi ve Anaokulu İnşaatı
              </p>
              <p>
                • <b>2020</b>: Ankara Keçiören Anadolu İmam Hatip Lisesi ve Spor Salonu İnşaatı
              </p>
              <p>
                • <b>2020</b>: Ankara Polatlı Sevim Aras İlkokulu-Ortaokulu İnşaatı
              </p>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default testimonials;
