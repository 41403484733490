import React, { Component } from 'react'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';


export class Slideshow extends Component {

  render() {
    return (
      <div className="slide-container" style={{ height: "975px", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Slide style={{ height: "800px", width: "90%" }}>
          <div style={{ height: "800px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
            <div style={{ height: "975px", width: "100%", objectFit: "cover", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={'../img/galeri/gokcebel2.JPG'} style={{ height: "975px", width: "90%", objectFit: "cover" }}>
              </img>
            </div>
          </div>
          <div style={{ height: "975px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ height: "975px", width: "100%", objectFit: "cover", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={'../img/galeri/yalikavak2.JPG'} style={{ height: "975px", width: "90%", objectFit: "cover" }}>
              </img>
            </div>
          </div>
          <div style={{ height: "975px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ height: "975px", width: "100%", objectFit: "cover", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={'../img/galeri/gumusluk4.jpg'} style={{ height: "975px", width: "90%", objectFit: "cover" }} >
              </img>
            </div>
          </div>
          <div style={{ height: "975px", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ height: "975px", width: "100%", objectFit: "cover", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={'../img/galeri/golbasi1.jpg'} style={{ height: "975px", width: "90%", objectFit: "cover" }} >
              </img>
            </div>
          </div>
        </Slide>
      </div>
    )
  }
}

export default Slideshow
