import React, { Component } from "react";

export class features extends Component {
  render() {
    return (
      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>DEVAM EDEN PROJELERİMİZ</h2>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-sm-6" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                  {" "}
                  <div className="kutu">
                    <img src={d.img}
                      style={{ height: "100%", objectFit: "cover" }}></img>
                  </div>
                  <div>
                    <h3>{d.title}</h3>
                    <h3>{d.title1}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
              : "Loading..."}
          </div>
        </div>
      </div>
    );
  }
}

export default features;
