import React, { Component } from 'react'

export class Team extends Component {
  render() {
    return (
      <div id="team">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6"> <img src="img/arkaplanhiz.jpg" className="img-responsive" alt="" /> </div>
            <div className="col-xs-12 col-md-6" style={{fontSize:"16"}}>
              <div className="team-text">
                <h2>HİZMET POLİTİKAMIZ</h2>
                <p>{this.props.data ? this.props.data.paragraph : 'loading...'}</p>
                <div className="list-style">
                  <div>
                    <ul>
                      {this.props.data ? this.props.data.Why.map((d, i) => <li key={`${d}-${i}`}>{d}</li>) : 'loading'}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Team

