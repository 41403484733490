import React, { Component } from "react";

export class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_row: 2,
    }
  }

  choose0() {
    this.setState({ selected_row: 0 })
  }
  choose1() {
    this.setState({ selected_row: 1 })
  }
  choose2() {
    this.setState({ selected_row: 2 })
  }

  render() {
    return (
      <div id="services" className="section-title text-center" style={{ display: "flex", width: "100%", height: "100%", flexDirection: "column" }}>
        <div style={{ width: "100%", height: "10%" }}>
          <h2 style={{ color: "black" }}>
            KİŞİSEL VERİLERİ KORUMA KANUNU
          </h2>
        </div>
        <div style={{ width: "100%", height: "100%" }}>
          <div style={{ display: "flex", width: "100%", height: "90%", flexDirection: "row" }}>
            <div style={{ width: "50%", height: "90%", marginTop: "2%", flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
              {this.state.selected_row == 0 &&
                <button style={{
                  width: "50%", height: "90", marginLeft: "25%", backgroundColor: "#6495ED", display: "flex", alignItems: "center",
                  fontSize: "15px", mozBorderRadius: "80px", webkitBorderRadius: "6px", outline: "None", backgroundPosition: "9px 0px", backgroundRepeat: "no-repeat", border: "solid 1px #3498db", borderRadius: "20px",
                  lineHeight: "18px", overflow: "hidden", padding: "15px 60px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
                }} >
                  <p style={{ color: "#404040" }}>
                    <b>KİŞİSEL VERİLERİN KORUNMASI - AYDINLATMA METNİ</b>
                  </p>
                </button>
              }
              {this.state.selected_row != 0 &&
                <button style={{
                  width: "50%", height: "90", marginLeft: "25%", backgroundColor: "#F5FFFA", display: "flex", alignItems: "center", fontSize: "15px", mozBorderRadius: "80px",
                  webkitBorderRadius: "6px", outline: "None", backgroundPosition: "9px 0px", backgroundRepeat: "no-repeat", border: "solid 1px #3498db", borderRadius: "20px",
                  lineHeight: "18px", overflow: "hidden", padding: "15px 60px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
                }}
                  onClick={this.choose0.bind(this)}>
                  <p style={{ color: "#404040" }}>
                    <b>KİŞİSEL VERİLERİN KORUNMASI - AYDINLATMA METNİ</b>
                  </p>
                </button>
              }
              {this.state.selected_row == 1 &&
                <button style={{
                  width: "50%", height: "90", backgroundColor: "#6495ED", marginLeft: "25%", marginTop: "1%", display: "flex", alignItems: "center",
                  mozBorderRadius: "80px", webkitBorderRadius: "6px", outline: "None", backgroundPosition: "9px 0px", backgroundRepeat: "no-repeat", border: "solid 1px #3498db", borderRadius: "20px",
                  lineHeight: "18px", overflow: "hidden", padding: "15px 60px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
                }} >
                  <p style={{ color: "#404040" }}>
                    <b>KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ POLİTİKASI</b>
                  </p>
                </button >
              }
              {this.state.selected_row != 1 &&
                <button style={{
                  width: "50%", height: "90", backgroundColor: "#F5FFFA", marginLeft: "25%", marginTop: "1%", display: "flex", alignItems: "center",
                  mozBorderRadius: "80px", webkitBorderRadius: "6px", outline: "None", backgroundPosition: "9px 0px", backgroundRepeat: "no-repeat", border: "solid 1px #3498db", borderRadius: "20px",
                  lineHeight: "18px", overflow: "hidden", padding: "15px 60px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
                }} onClick={this.choose1.bind(this)}>
                  <p style={{ color: "#404040" }}>
                    <b>KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ POLİTİKASI</b>
                  </p>
                </button >
              }
              {this.state.selected_row == 2 &&
                <button style={{
                  width: "50%", height: "90", backgroundColor: "#6495ED", marginLeft: "25%", marginTop: "1%", display: "flex", alignItems: "center", mozBorderRadius: "80px", webkitBorderRadius: "6px", outline: "None",
                  backgroundPosition: "9px 0px", backgroundRepeat: "no-repeat", border: "solid 1px #3498db", borderRadius: "20px",
                  lineHeight: "18px", overflow: "hidden", padding: "15px 60px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
                }}>
                  <p style={{ color: "#404040" }}>
                    <b>VERİ SORUMLUSUNA BAŞVURU FORMU</b>
                  </p>
                </button>
              }
              {this.state.selected_row != 2 &&
                <button style={{
                  width: "50%", height: "90", backgroundColor: "#F5FFFA", marginLeft: "25%", marginTop: "1%", display: "flex", alignItems: "center", mozBorderRadius: "80px", webkitBorderRadius: "6px", outline: "None",
                  backgroundPosition: "9px 0px", backgroundRepeat: "no-repeat", border: "solid 1px #3498db", borderRadius: "20px",
                  lineHeight: "18px", overflow: "hidden", padding: "15px 60px", boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
                }} onClick={this.choose2.bind(this)}>
                  <p style={{ color: "#404040" }}>
                    <b>VERİ SORUMLUSUNA BAŞVURU FORMU</b>
                  </p>
                </button>
              }

            </div>

            <div style={{ width: "50%", height: "100%", backgroundColor: "black", marginTop: "2%", marginRight: "2%" }}>
              {this.state.selected_row == 0 &&
                <div>
                  <object data="/Kisisel-Veriler.pdf" type="application/pdf" width="100%" height="100%">
                  </object>
                </div>
              }
              {this.state.selected_row == 1 &&
                <div>
                  <object data="/Politika.pdf" type="application/pdf" width="100%" height="100%">
                  </object>
                </div>
              }
              {this.state.selected_row == 2 &&
                <div>
                  <object data="/Veri-Sorumlusu.pdf" type="application/pdf" width="100%" height="100%">
                  </object>
                </div>
              }
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default Services;
