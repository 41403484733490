import React, { Component } from "react";

export class Contact extends Component {
  render() {
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>İLETİŞİM BİLGİLERİ</h2>
                  <p>
                    Bizlere aşağıdaki formu doldurarak ulaşabilirsiniz.
                  </p>
                </div>
                <form name="sentMessage" id="contactForm" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          required="required"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Mesaj Gönder
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Adres
                  </span>
                  {this.props.data ? this.props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> İletişim
                  </span>{" "}
                  {this.props.data ? this.props.data.phone : "loading"}<br></br>
                  {this.props.data ? this.props.data.phone1 : "loading"}<br></br>
                  {this.props.data ? this.props.data.phone2 : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Mail
                  </span>{" "}
                  {this.props.data ? this.props.data.email : "loading"}<br></br>
                  {this.props.data ? this.props.data.email1 : "loading"}<br></br>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
