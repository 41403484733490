import React, { Component } from "react";

export class Gallery extends Component {
  render() {
    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Fotoğraflar</h2>
            <p>
              YAPIMI TAMAMLANAN PROJELERİMİZDEN FOTOĞRAFLAR
            </p>
          </div>
          <div className="row">
            <div className="portfolio-items">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" >
                    {" "}
                    <a
                        href="img/galeri/gumusluk3.jpg"
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>BODRUM GÜMÜŞLÜK <br></br> YAPRAK VİLLALARI</h4>
                      </div>
                      <img
                          src="img/galeri/gumusluk3.jpg"
                          style={{ height: "250px", width: "100%" }}
                          className="img-responsive"
                          alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" >
                    {" "}
                    <a
                        href="img/galeri/gumusluk4.jpg"
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>BODRUM GÜMÜŞLÜK <br></br> YAPRAK VİLLALARI</h4>
                      </div>
                      <img
                          src="img/galeri/gumusluk4.jpg"
                          style={{ height: "250px", width: "100%" }}
                          className="img-responsive"
                          alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" >
                    {" "}
                    <a
                        href="img/galeri/gumusluk2.jpg"
                        title="Project Title"
                        data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>BODRUM GÜMÜŞLÜK <br></br> YAPRAK VİLLALARI</h4>
                      </div>
                      <img
                          src="img/galeri/gumusluk2.jpg"
                          style={{ height: "250px", width: "100%" }}
                          className="img-responsive"
                          alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg" >
                    {" "}
                    <a
                      href="img/galeri/yalikavak1.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>BODRUM YALIKAVAK <br></br> YAPRAK VİLLALARI</h4>
                      </div>
                      <img
                        src="img/galeri/yalikavak1.JPG"
                        style={{ height: "250px", width: "100%" }}
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/yalikavak2.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>BODRUM YALIKAVAK <br></br> YAPRAK VİLLALARI</h4>
                      </div>
                      <img
                        src="img/galeri/yalikavak2.JPG"
                        style={{ height: "250px", width: "100%" }}
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/yalikavak3.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>BODRUM YALIKAVAK <br></br> YAPRAK VİLLALARI</h4>
                      </div>
                      <img
                        src="img/galeri/yalikavak3.JPG"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/gokcebel1.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>BODRUM GÖKÇEBEL NİLTAŞ EVLERİ</h4>
                      </div>
                      <img
                        src="img/galeri/gokcebel1.jpg"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/gokcebel2.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>BODRUM GÖKÇEBEL NİLTAŞ EVLERİ</h4>
                      </div>
                      <img
                        src="img/galeri/gokcebel2.JPG"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/gokcebel3.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>BODRUM GÖKÇEBEL İŞ MERKEZİ</h4>
                      </div>
                      <img
                        src="img/galeri/gokcebel3.JPG"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/golbasi1.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>GÖLBAŞI SEĞMENLER KIZ MESLEK LİSESİ VE ANAOKULU YAPIM İŞİ</h4>
                      </div>
                      <img
                        src="img/galeri/golbasi1.jpg"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/golbasi2.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>GÖLBAŞI SEĞMENLER KIZ MESLEK LİSESİ VE ANAOKULU YAPIM İŞİ</h4>
                      </div>
                      <img
                        src="img/galeri/golbasi2.jpg"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/golbasi3.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>GÖLBAŞI SEĞMENLER KIZ MESLEK LİSESİ VE ANAOKULU YAPIM İŞİ</h4>
                      </div>
                      <img
                        src="img/galeri/golbasi3.JPG"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/kecioren1.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>KEÇİÖREN ANADOLU İMAM HATİP LİSESİ</h4>
                      </div>
                      <img
                        src="img/galeri/kecioren1.jpg"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/kecioren2.jpeg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>KEÇİÖREN ANADOLU İMAM HATİP LİSESİ</h4>
                      </div>
                      <img
                        src="img/galeri/kecioren2.jpeg"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/kecioren3.jpeg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>KEÇİÖREN ANADOLU İMAM HATİP LİSESİ</h4>
                      </div>
                      <img
                        src="img/galeri/kecioren3.jpeg"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/kizilay.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>ANKARA KIZILAY PTT BYS MÜDÜRLÜĞÜ MİSAFİRHANE BİNASI</h4>
                      </div>
                      <img
                        src="img/galeri/kizilay.JPG"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/fethiye.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>FETHİYE ÜZÜMLÜ 8 DERSLİKLİ ORTAOKUL İNŞAATI</h4>
                      </div>
                      <img
                        src="img/galeri/fethiye.jpg"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/samsun1.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>SAMSUN ATAKUM PTT MERKEZ MÜDÜRLÜĞÜ UYGULAMALI EĞİTİM OTEL BİNASI İNŞAATI</h4>
                      </div>
                      <img
                        src="img/galeri/samsun1.JPG"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/aselsan.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>ASELSAN İLKOKULU İNŞAATI</h4>
                      </div>
                      <img
                        src="img/galeri/aselsan.JPG"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/dodurga.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>ANKARA DODURGA MAHALLESİ YENİMAHALLE ANADOLU LİSESİ İNŞAATI</h4>
                      </div>
                      <img
                        src="img/galeri/dodurga.JPG"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/mamak.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>MAMAK ORTAOKULU, ANAOKULU VE SPOR SALONU İNŞAATI</h4>
                      </div>
                      <img
                        src="img/galeri/mamak.JPG"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/beypazari.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>BEYPAZARI İLKÖĞRETİM OKULU İNŞAATI</h4>
                      </div>
                      <img
                        src="img/galeri/beypazari.JPG"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/uygur.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>KEÇİÖREN UYGUR İLKÖĞRETİM OKULU İNŞAATI</h4>
                      </div>
                      <img
                        src="img/galeri/uygur.JPG"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/galeri/samil.JPG"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>ETİMESGUT ŞEYH ŞAMİL ORTAOKULU İNŞAATI</h4>
                      </div>
                      <img
                        src="img/galeri/samil.JPG"
                        className="img-responsive"
                        style={{ height: "250px", width: "100%" }}
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
