import React, { Component } from "react";

export class Navigation extends Component {
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container" >
          <div className="navbar-header" >
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
              <img style={{height:'40'}}
                src="img/logo.jpg"
                alt="Project Title"
              />
            <a className="navbar-brand page-scroll" href="#page-top">
              Nİltaş İnşaat
            </a>{" "}
            <div>
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
              >
                {" "}
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}

              </button>

            </div>
          </div>
          </div>
          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"

          >
            <ul  className="nav navbar-nav navbar-right" >
              <li>
                <a  href="#about" className="page-scroll">
                  HAKKIMIZDA
                  </a>
              </li>
              <li>
                <a href="#features" className="page-scroll">
                  PROJELERİMİZ
                </a>
              </li>
              <li>
                <a href="#portfolio" className="page-scroll">
                  GALERİ
                </a>
              </li>
              <li>
                <a href="#testimonials" className="page-scroll">
                  REFERANSLAR
                </a>
              </li>
              <li>
                <a href="#team" className="page-scroll">
                  HİZMET POLİTİKAMIZ
                </a>
              </li>
              <li>
                <a href="#services" className="page-scroll">
                   İK
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  İLETİŞİM
                </a>
              </li>

            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
